@import "ClientApp/styles/base/base.scss";
:root {
  /*LAYOUT*/
  --layout-background: var(--color-grey-lighter);
  --layout-areas: 'header header' 'usp usp' 'nav main' 'footer footer';
  --layout-template-columns: min-content 1fr;
  --layout-template-row: repeat(4, auto);
  --nav-area: nav;
  --container-width-small: #{$container-width-small};
  --container-width: #{$container-width};
  --container-width-large: #{$container-width-large};
  --padding-mini: #{$padding-mini};
  --padding-xsmall: #{$padding-xsmall};
  --padding-small: #{$padding-small};
  --padding-medium: #{$padding-medium};
  --padding-large: #{$padding-large};
  --gap-column-mini: #{$padding-mini};
  --gap-column-xsmall: #{$padding-xsmall};
  --gap-column-small: #{$padding-small};
  --gap-row-small: #{$padding-small};
  --gap-column-medium: #{$padding-medium};
  --gap-row-medium: #{$padding-medium};
  --view-max: 1700px;
  --view-small-max: 1200px;
  --layout-ups-background-color: var(--color-grey-light);
  /* LOGO */
  --logo: none;
  --logo-height: 70px;
  --logo-width: 120px;
  --logo-mobile: none;
  --logo-height-mobile: 34px;
  --logo-width-mobile: 187px;
  --logo-alt: none;
  --logo-alt-height: 120px;
  --logo-alt-width: 235px;
  /*COLORS*/
  --color-brown: #{$clr-brown};
  --color-grey-menutoggle: #{$clr-grey-menutoggle};
  --color-grey-light: #{$clr-grey-light};
  --color-grey-light-second: #{$clr-grey-light-second};
  --color-grey-lighter: #{$clr-grey-lighter};
  --color-grey-lightest: #{$clr-grey-lightest};
  --color-grey-medium: #{$clr-grey-medium};
  --color-grey-dark: #{$clr-grey-dark};
  --color-grey-mid: #{$clr-grey-mid};
  --color-blue-grey: #{$clr-blue-grey};
  --color-blue-dark: #{$clr-blue-dark};
  --color-blue: #{$clr-blue};
  --color-blue-light: #{$clr-blue-light};
  --color-blue-lighter: #{$clr-blue-lighter};
  --color-green-lighter: #{$clr-green-lighter};
  --color-green-light: #{$clr-green-light};
  --color-green: #{$clr-green};
  --color-green-dark: #{$clr-green-dark};
  --color-green-darker: #{$clr-green-darker};
  --color-green-super-light: #{$clr-green-super-light};
  --color-pink: #{$clr-pink};
  --color-red-light: #{$clr-red-light};
  --color-red: #{$clr-red};
  --color-white: #{$clr-white};
  --color-white-darker: #{$clr-white-darker};
  --color-black: #{$clr-black};
  --color-yellow-light: #{$clr-yellow-light};
  --color-yellow: #{$clr-yellow};
  --color-red-dark: #{$clr-red-dark};
  /*FONT*/
  --font-color: var(--color-black);
  --font-color-light: var(--color-white);
  /*ELEMENTS*/
  --element-height-small: 40px;
  --element-height-medium: 50px;
  --element-radius: 6px;
  --element-height: 50px;
  /*BUTTONS*/
  --button-min-height-mobile: 44px;
  /* primary base */
  --button-primary-background-color: var(--color-green);
  --button-primary-border-color: var(--color-green);
  --button-primary-color: var(--color-white);
  /* primary hover */
  --button-primary-background-color-hover: var(--color-green-light);
  --button-primary-color-hover: var(--button-primary-background-color-hover);
  /* primary active */
  --button-primary-background-color-active: var(--button-primary-background-color-hover);
  --button-primary-color-active: var(--button-primary-background-color-hover);
  /* secondary base */
  --button-secondary-background-color: var(--color-blue);
  --button-secondary-border-color: var(--color-blue);
  --button-secondary-color: var(--color-white);
  /* primary hover */
  --button-secondary-background-color-hover: var(--color-blue-light);
  --button-secondary-color-hover: var(--button-secondary-background-color-hover);
  /* primary active */
  --button-secondary-background-color-active: var(--button-secondary-background-color-hover);
  --button-secondary-color-active: var(--button-secondary-background-color-hover);
  /* primary base */
  --button-disabled-background-color: var(--color-grey-lighter);
  --button-disabled-border-color: var(--color-white);
  --button-disabled-color: var(--color-grey-medium);
  /*ALERTS*/
  --alert-success-background: var(--color-green-light);
  --alert-success-color: var(--color-green);
  --alert-warning-background: var(--color-yellow-light);
  --alert-warning-color: var(--color-yellow);
  --alert-error-background: var(--color-red-light);
  --alert-error-color: var(--color-red);
  /*HEADER LOCATION*/
  --location-header-height: auto;
  --location-header-desktop-height: auto;
  --location-header-shrink-height: 70px;
  /*HEADER*/
  --header-grid-area-mobile: 'logo actions' 'search search';
  --header-grid-columns-mobile: auto 1fr;
  --header-grid-rows-mobile: auto 1fr;
  --header-grid-area-tablet: 'logo actions actions' 'search search search';
  --header-grid-columns-tablet: auto 1fr auto;
  --header-grid-rows-tablet: auto auto;
  --header-grid-area: 'logo search actions';
  --header-grid-columns: auto 1fr auto;
  --header-grid-rows: 1fr;
  --header-icon-search: none;
  --header-background: var(--color-white);
  --header-container-background: var(--color-white);
  --header-nav-anchor: var(--font-color);
  --header-nav-anchor-hover: var(--color-grey-dark);
  --header-height: 140px;
  --header-mobile-height: 80px;
  --header-shrink-height: 70px;
  --header-height: 100px;
  --header-shrink-height: 100px;
  --header-height-tablet: 130px;
  --site-promo-dropdown-height: 30px;
  --site-promo-dropdown-anchor-padding: 0 var(--padding-medium) 0 var(--padding-small);
  --site-promo-dropdown-anchor-icon-position: 10px;
  /*NAV*/
  --main-nav-background-color: var(--color-white);
  --main-nav-width: 250px;
  --main-nav-direction: column;
  /*SEARCH*/
  --main-mobile-search: block;
  --main-search: block;
  --main-search-box-shadow: none;
  --main-search-background-color: var(--color-grey-lighter);
  --main-search-grid-areas: 'input clear-icon search-icon';
  --main-search-grid-columns: 1fr min-content min-content;
  --main-search-border-radius: 100px;
  --main-search-button-background-color: transparent;
  --main-search-input-background-color: transparent;
  --main-search-margin: 0 auto;
  --main-search-margin-screen-medium: 0 0 var(--padding-xsmall) 0;
  --main-search-padding: 0;
  /*PRODUCT GLOBAL*/
  --buysection-dropdown-radius: var(--element-radius) 0 0 var(--element-radius);
  --buysection-button-radius: 0 0 var(--element-radius) var(--element-radius);
  --buysection-dropdown-radius_mobile: var(--element-radius) var(--element-radius) 0 0;
  --buysection-button-radius_mobile: 0 0 var(--element-radius) var(--element-radius);
  /*PRODUCT CARD*/
  --product-card-background: var(--color-white);
  --portrait-card-background: var(--color-white);
  --product-card-margin: 0;
  --product-card-padding: var(--padding-xsmall);
  --product-card-radius: var(--element-radius);
  --product-card-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.1);
  --product-card-grid-gap: var(--padding-xsmall) var(--padding-small);
  --product-card-grid-gap-mobile: var(--padding-mini) var(--padding-xsmall);
  --product-image-background-color: var(--color-grey-lighter);
  --product-image-padding: 0 var(--padding-xsmall);
  --product-image-radius: var(--element-radius);
  --product-image-margin-screen-medium: 0;
  --product-image-padding-screen-medium: var(--padding-xsmall);
  --product-image-img-padding-screen-medium: 0;
  --product-image-img-margin-screen-medium: 0;
  --product-image-img-margin: 0;
  --product-image-img-padding: var(--padding-xsmall);
  --product-image-img-width: 200px;
  --product-image-img-padding-screen-medium: 0 var(--padding-medium);
  --product-image-img-margin-screen-medium: var(---padding-medium);
  --product-actions-m-bottom: 0;
  --product-actions-m-bottom-screen-medium: 0;
  --product-actions-buy-radius: var(--element-radius);
  --product-card-single-price-background-color: var(--color-grey-lighter);
  --product-card-single-price-text-color: var(--font-color);
  --product-card-single-price-radius: var(--element-radius);
  --product-card-dropdown-background-color: var(--color-grey-lightest);
  --product-card-dropdown-background-radius: 0;
  --product-image-flag-top: 0;
  --product-image-flag-left: 0;
  --product-image-flag-right: unset;
  --product-image-flag-bottom: unset;
  --product-image-flag-height: auto;
  --product-image-flag-width: auto;
  --product-image-flag-radius: 14px;
  --product-image-flag-transform: translateY(-80%);
  --product-image-flag-top-screen-medium: var(--padding-small) + var(--padding-xsmall);
  --product-image-flag-right-screen-medium: var(--padding-small);
  --product-image-flag-left-screen-medium: auto;
  --product-image-flag-text-height: auto;
  --product-image-flag-text-width: auto;
  --product-image-flag-text-border: none;
  --product-image-flag-text-padding: 0.3rem 1rem;
  --product-image-flag-text-font-size: 14px;
  /*PRODUCTPAGE*/
  --productpage-status-description-maxwidth: 250px;
  --productpage-butsection-dropdown-radius: var(--element-radius) 0 0 var(--element-radius);
  --productpage-butsection-button-radius: 0 var(--element-radius) var(--element-radius) 0;
  --productpage-img-width: 245px;
  --productpage-attributes-background-color: var(--color-green);
  /*FILTER*/
  --filter-background: #fff;
  --filter-input-height: 50px;
  --filter-font-size: 16px;
  --filter-font-header-size: 20px;
  --clear-all-filters-bg-clr-hover: var(--color-grey-light);
  /*FOOTER*/
  --footer-bg-color: none;
  --footer-top-bg: inherit;
  --footer-top-bg-images: none;
  --footer-top-bg-color: transparent;
  --footer-top-text: var(--color-white);
  --footer-top-margin-top: 0;
  --footer-top-m-top: 100px;
  --footer-usp-bg-image: none;
  --footer-usp-bg-color: none;
  --footer-usp-pad: var(--padding-large) var(--padding-medium);
  --footer-usp-icon-size: 80px;
  --footer-show-left-right-element: none;
  --footer-show-left-right-element: block;
  --footer-bottom-background: none;
  --footer-bottom-text-color: var(--font-color);
  --footer-bottom-header-color: var(--font-color);
  /*SCROLL-BUTTON*/
  --scroll-button-background: var(--color-green-super-light);
  --scroll-button-background-hover: var(--color-green-dark);
  --scroll-button-icon: var(--color-green-darker);
  --scroll-button-icon-hover: var(--color-white);
  /*STEPPER*/
  --stepper-active-background: var(--color-blue-grey);
  /*INPUT*/
  --input-autofill: var(--color-white);
  /* ICONS REF  */
  /*DARK-THEME*/
  --dark-c-bg: #222;
  --dark-f-color: var(--color-white);
  --dark-icon-color: #ddd;
  --dark-header-icon-size: 30px;
  --dark-b-lg-0: var(--color-black);
  --dark-b-lg-20: #1e1e1e;
  --dark-b-lg-sec-20: #fd495c;
  --dark-b-lg-sec-80: #ff3b3b;
  --dark-b-lg-tree-80: var(--color-black);
  --dark-b-lg-tree-100: var(--dark-c-bg);
  --dark-pc-bg-0: var(--color-black);
  --dark-pc-bg-80: #1e1e1e;
  --dark-b-custom-select: #111;
  --dark-header-bg: #333;
  --dark-footer-top-bg: #111;
  --dark-filter-active-color: var(--color-white);
  --dark-product-info-color: #ccc;
  --dark-paging-bg: var(--dark-c-bg);
  --dark-paging-bg-hover: #333;
  --dark-paging-color: var(--dark-f-color);
}
