.clear-both {
  clear: both;
}

// Image Utilities
.contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.fill {
  object-fit: fill;
}
.scale-down {
  object-fit: scale-down;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.anchor-center,
.anchor-right {
  display: grid;
}

.anchor-center {
  margin: 0 auto;
  place-content: center;
}

.anchor-right {
  place-content: right;
}

.full-width {
  width: 100%;
  display: grid;
  place-content: center;
}

div[data-container="section"] {
  div {
    > p, span, ul, ol {
      a {
        font-weight: bold;
        text-decoration: underline;
      }

      a:hover {
        color: var(--color-blue-dark);
      }
    }
  }
}

article > section {
  a {
    font-weight: bold;
    text-decoration: underline;
  }

  a:hover {
    color: var(--color-blue-dark);
  }
}

.text-underlined, .text-underlined:hover {
    text-decoration: underline;
}

input:autofill,
input:-webkit-autofill {
  background-color: var(--input);
  &:hover,
  &:focus,
  &:active,
  &:target {
    background-color: var(--input);
  }
}
